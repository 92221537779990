import { Controller } from "stimulus";

/**
 * @author Kiril Mitov
 */
export default class extends Controller {
  static targets = ["commit", "term"];

  connect() {
    this.enableCommit();

    this.autocompleteRails();

    // TODO:
    // Hate it to use jquery here
    //
    $(this.termTarget).bind("railsAutocomplete.select", (event, data) => {
      const form = this.commitTarget.form;
      form.dispatchEvent(new Event("submit", { bubbles: true }));
    });
  }

  /**
   * This is need to enable to commit button of the form on back button.
   * When a Turbolink restore is called for this form it is already cached with the
   * value of when it was commited. It was commited and it was disabled because
   * of disable_with. But button is cached as disabled. We need to
   * re-enable it.
   *
   * We also need to set the name of the button since it is now "Processing..."
   * @private
   */
  enableCommit() {
    const commit = this.commitTarget;
    commit.disabled = false;
    commit.value = commit.dataset["value"];
  }

  autocompleteRails() {
    const controller = this;

    // THIS IS JUST COPIED FROM https://raw.githubusercontent.com/peterwillcn/rails4-autocomplete/master/lib/assets/javascripts/autocomplete-rails-uncompressed.js
    //
    // and is modified to work with the stimulus controller
    /*
     * Unobtrusive autocomplete
     *
     * To use it, you just have to include the HTML attribute autocomplete
     * with the autocomplete URL as the value
     *
     *   Example:
     *       <input type="text" data-autocomplete="/url/to/autocomplete">
     *
     * Optionally, you can use a jQuery selector to specify a field that can
     * be updated with the element id whenever you find a matching value
     *
     *   Example:
     *       <input type="text" data-autocomplete="/url/to/autocomplete" data-id-element="#id_field">
     */

    var self = null;
    var options = {};
    jQuery.fn.railsAutocomplete = function() {
      var handler = function() {
        if (!this.railsAutoCompleter) {
          this.railsAutoCompleter = new jQuery.railsAutocomplete(this);
        }
      };
      let selector = controller.termTarget.id;
      options[selector] = arguments[0];
      if (jQuery.fn.on !== undefined) {
        return $(document).on("focus", "#" + selector, handler);
      } else {
        return this.live("focus", handler);
      }
    };

    jQuery.railsAutocomplete = function(e) {
      this.init(e);
    };

    jQuery.railsAutocomplete.fn = jQuery.railsAutocomplete.prototype = {
      railsAutocomplete: "0.0.2"
    };

    jQuery.railsAutocomplete.fn.extend = jQuery.railsAutocomplete.extend = jQuery.extend;
    jQuery.railsAutocomplete.fn.extend({
      init: function(e) {
        e.delimiter = jQuery(e).attr("data-delimiter") || null;
        function split(val) {
          return val.split(e.delimiter);
        }
        function extractLast(term) {
          return split(term)
            .pop()
            .replace(/^\s+/, "");
        }

        jQuery(e).autocomplete(
          $.extend(
            {
              source: function(request, response) {
                jQuery.getJSON(
                  jQuery(e).attr("data-autocomplete"),
                  {
                    term: extractLast(request.term)
                  },
                  function() {
                    if (arguments[0].length == 0) {
                      var label = "No existing matches";
                      if (jQuery(e).attr("data-autocomplete-label") !== undefined) {
                        label = jQuery(e).attr("data-autocomplete-label");
                      }
                      arguments[0] = [];
                      arguments[0][0] = { id: "", label: label };
                    }
                    jQuery(arguments[0]).each(function(i, el) {
                      var obj = {};
                      obj[el.id] = el;
                      jQuery(e).data(obj);
                    });
                    response.apply(null, arguments);
                  }
                );
              },
              change: function(event, ui) {
                if (jQuery(jQuery(this).attr("data-id-element")).val() == "") {
                  return;
                }
                jQuery(jQuery(this).attr("data-id-element")).val(ui.item ? ui.item.id : "");
                var update_elements = false;
                if (jQuery(this).attr("data-update-elements")) {
                  update_elements = jQuery.parseJSON(jQuery(this).attr("data-update-elements"));
                }
                var data = ui.item ? jQuery(this).data(ui.item.id.toString()) : {};
                if (update_elements && jQuery(update_elements["id"]).val() == "") {
                  return;
                }
                for (var key in update_elements) {
                  jQuery(update_elements[key]).val(ui.item ? data[key] : "");
                }
              },
              minLength: 2,
              focus: function() {
                // prevent value inserted on focus
                return false;
              },
              select: function(event, ui) {
                var terms = split(this.value);
                // remove the current input
                terms.pop();
                // add the selected item only if it has something
                if (ui.item.id != "") {
                  terms.push(ui.item.value);
                }
                // add placeholder to get the comma-and-space at the end
                if (e.delimiter != null) {
                  terms.push("");
                  this.value = terms.join(e.delimiter);
                } else {
                  this.value = terms.join("");
                  if (jQuery(this).attr("data-id-element")) {
                    jQuery(jQuery(this).attr("data-id-element")).val(ui.item.id);
                  }
                  if (jQuery(this).attr("data-update-elements")) {
                    var data = jQuery(this).data(ui.item.id.toString());
                    var update_elements = jQuery.parseJSON(jQuery(this).attr("data-update-elements"));
                    for (var key in update_elements) {
                      jQuery(update_elements[key]).val(data[key]);
                    }
                  }
                }
                var remember_string = this.value;
                jQuery(this).bind("keyup.clearId", function() {
                  if (
                    jQuery(this)
                      .val()
                      .trim() != remember_string.trim()
                  ) {
                    jQuery(jQuery(this).attr("data-id-element")).val("");
                    jQuery(this).unbind("keyup.clearId");
                  }
                });
                jQuery(e).trigger("railsAutocomplete.select", ui);
                return false;
              }
            },
            options[e.id]
          )
        );
      }
    });

    jQuery(document).ready(function() {
      jQuery("input[data-autocomplete]").railsAutocomplete();
      if(document.activeElement == controller.termTarget) {
        // A hack to change the focus so that an event could be
        // send and railsAutocomplete could attach after the focus is set
        // Otherwise as the focus is set before the jQuery.ready event
        // when there is autofocus, the railsAutocomplete will never
        // receive a focus event
        controller.commitTarget.focus()
        controller.termTarget.focus();
      }
    });
  }
}
