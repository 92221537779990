import { Controller } from "stimulus";

/**
 * This is a controller used for filtering by brands [categories] on the materials index page
 *
 * @author Kiril Mitov
 */
export default class extends Controller {
  static targets = ["tree"];

  connect() {
    const scope = this;
    // NOTE
    // The checked attribute is returned from the server.
    // No need to set them here with
    //   this.setFromUrl();
    //
    // This makes the code less dependent on js. Probably even the filter
    // should be a form that is submitted and in this way there will be no Js.
    //
    // But we still want for user to click on on checkbox and filter
    this.treeTarget.addEventListener("click", e => {
      e.preventDefault();
      const li = e.target.closest("li");
      const input = li.querySelector("input");
      input.checked = !input.checked;
      scope.goToNewLocation();
    });
  }

  // setFromUrl() {
  //   const url = new URL(window.location);
  //   const categoryIds = new URL(window.location).searchParams.getAll("in_categories[]");

  //   Array.from(this.treeTarget.querySelectorAll("li[data-category-id]")).forEach(li => {
  //     const input = li.querySelector("input");
  //     const selected = categoryIds.indexOf(li.dataset["categoryId"]) != -1;
  //     if (selected) {
  //       input.setAttribute("checked", selected)
  //     }
  //     input.checked = selected;
  //   });
  // }

  goToNewLocation() {
    const url = new URL(window.location);
    const searchParams = url.searchParams;
    searchParams.delete("in_categories[]");
    searchParams.delete("page");
    searchParams.delete("per_page");
    Array.from(this.treeTarget.querySelectorAll("li[data-category-id]"))
      .filter(li => li.querySelector("input").checked)
      .forEach(li => searchParams.append("in_categories[]", li.dataset["categoryId"]));
    searchParams.sort();
    window.location = url.toString();
  }
}
