import { Controller } from "stimulus";

/**
 * @author Kiril Mitov
 */
export default class extends Controller {
  static targets = ["datatable"];
  static values = {
    source: String
  };
  connect() {
    //
    // Make it look like the others form controls
    $.fn.DataTable.ext.classes.sFilterInput += "form-control g-py-15 g-px-15";

    const table = $(this.datatableTarget).DataTable({
      processing: true,
      serverSide: true,
      ajax: {
        url: this.sourceValue
      },
      // Disables the initial ordering of the table
      "order": [[5, "desc"]],
      deferRender: true,
      pagingType: "simple",
      searchPanes: true,
      //      dom: 'Pfrtip',
      // dom: 'frtip',
      //
      // determines how many elements should be displayed per page
      // I'd suggest uncommenting only for testing purposes
      // since there's a default value and
      // a select box is generated on the table page
      // with additional values
      //
      // pageLength: 1,
      language: {
        paginate: {
          next: `<li class='list-inline-item'>
          <span class='g-ml-10 u-pagination-v1__item u-pagination-v1-1 g-pa-12-21'' aria-label='Next'>
            <span aria-hidden='true'>
              <i class='fa fa-angle-right'></i>
            </span>
            <span class='sr-only'>Next</span>
          </span>
        </li>`,
          previous: `<li class='list-inline-item'>
          <span class='u-pagination-v1__item u-pagination-v1-1 g-pa-12-21'' aria-label='Previous'>
            <span aria-hidden='true'>
              <i class='fa fa-angle-left'></i>
            </span>
            <span class='sr-only'>Previous</span>
          </span>
        </li>`
        }
      },
      //
      // This callback hides the div containing
      // the pagination elements if the page number count
      // is <= 1
      //
      fnDrawCallback: function(datatable) {
        // hideNumbers();
        // Recalculate column width after drawing new data
        this.api().columns.adjust();
        // initialization of HSDropdown component
        // FIXME: This is a duplication with the fc/admin_unify/init
        $.HSCore.components.HSDropdown.init($("[data-dropdown-target]"), {
          dropdownHideOnScroll: false,
          dropdownType: "css-animation",
          dropdownAnimationIn: "fadeIn",
          dropdownAnimationOut: "fadeOut"
        });
      },
      columns: [
        { data: "title" },
        { data: "organization" },
        { data: "roles", orderable: false },
        { data: "participants" },
        { data: "free_spaces", orderable: false },
        { data: "created_at" },
        { data: "end_date" },
        { data: "actions", searchable: false, orderable: false }
      ]
    });
  }
}
