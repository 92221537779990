import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';
import 'babylonjs-serializers';

// import "@fortawesome/fontawesome-free/js/all.js";

require("jquery");

// for IS edit on the /author/materials/:id/is_edit page
window.Mustache = require("mustache");

// fancytree required for IS Editor
require('jquery.fancytree');

// dnd for fancytree required for IS Editor
require("jquery.fancytree/dist/modules/jquery.fancytree.dnd5");
require("jquery.fancytree/dist/modules/jquery.fancytree.multi");
require('jquery.fancytree/dist/modules/jquery.fancytree.ui-deps');