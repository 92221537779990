import { Controller } from "stimulus";
import "@google-web-components/google-chart";
import { createChartWrapper, dataTable } from "@google-web-components/google-chart/loader.js";

require("jquery-csv");

/**
 * @author Kiril Mitov
 */
export default class extends Controller {
  static targets = ["chartContainer", "downloadButton"];

  connect() {
    this.requestSource();
  }

  requestSource() {
    fetch(this.downloadButtonTarget.getAttribute("href"))
      .then(response => response.text())
      .then(csvString => {
        //The CSV comes in the form of
        // "Event Type,Data,On Step,UTC Timestamp,Material,User UUID,Created at,Build Id"
        // "1,1,,2019-05-21 22:04:12.111525000 +0300,1,e8297a156d05fa09bfd66c5b2fbd7bf2fea5718865266d995ad42ee6860222f5,2019-05-21 22:04:12 +0300"

        const parsedData = $.csv.toArrays(csvString, { onParseValue: $.csv.hooks.castToScalar });
        // From this parsed data we should produce
        // a arrayData that we feed to google chart
        // The format there for line chart should be
        // X -> stes
        // Y -> Time
        // This means
        // Final grid should be in the form of
        // grid = [
        //   ["Time", "build1","build1annotation","build2","build2annotation"],
        //   [1,1,null,null,null],
        //   [2,2,null,null,null],
        //   [3,3,some,null,null],
        //   [1,null,null,2,null],
        //   [2,null,null,3,some],
        //   [3,null,null,1,null],
        // ]

        const columnsPerIdentifier = 2;
        const grid = [...Array(parsedData.length)].map(e => Array(columnsPerIdentifier));
        const columns = parsedData[0].reduce(function(map, obj) {
          map[obj] = parsedData[0].indexOf(obj);
          return map;
        }, {});

        grid[0][0] = "Time";

        const ROTATE_EVENT_TYPES = [4, 5];
        //
        // Gather the build metadata- start time and end time of the build and where in the datatables it is
        // Also fill the grid for each build
        //
        const buildsData = {};
        for (let i = 1; i <= parsedData.length - 1; ++i) {
          const value = parsedData[i];
          const user = value[columns["User UUID"]];
          const eventType = value[columns["Event Type"]];
          const onStep = value[columns["On Step"]];
          const buildId = value[columns["Build Id"]];
          const identifier = buildId != 0 ? buildId : user;
          const parsedTime = value[columns["UTC Timestamp"]];

          if (buildsData[identifier] == undefined) {
            buildsData[identifier] = {};
            buildsData[identifier].indexInResultDataTable = Object.keys(buildsData).length - 1;
            buildsData[identifier].startTime = parsedTime;
          }

          buildsData[identifier].endTime = parsedTime;

          const onStepIndex = 1 + buildsData[identifier].indexInResultDataTable * columnsPerIdentifier;
          grid[0][onStepIndex] = identifier;
          grid[0][onStepIndex + 1] = { "type": "string", "role": "style" };

          //
          // time it took to get to this event from the begining of the build
          //
          grid[i][0] = parsedTime - buildsData[identifier].startTime;

          // the data for on step is column 1+buildsData[identifier].indexInResultDataTable but then
          // we could have colums for annotations we need acknowledge this. If there are two identifiers
          // than the annotation column is between them and the next identifier is not directly next to the first
          // identifier column
          grid[i][onStepIndex] = onStep + 1;
          grid[i][onStepIndex + 1] = ROTATE_EVENT_TYPES.includes(eventType)
            ? "point { size: 5; shape-type: circle; visible: true }"
            : null;
        }

        dataTable(grid).then(data => {
          let earliestStartTime = Infinity;
          let latestEndTime = 0;
          let slowestBuildTime = 0;
          Object.keys(buildsData).forEach(function(key) {
            latestEndTime = Math.max(latestEndTime, buildsData[key].endTime);
            earliestStartTime = Math.min(earliestStartTime, buildsData[key].startTime);
            slowestBuildTime = Math.max(slowestBuildTime, buildsData[key].endTime - buildsData[key].startTime);
          });

          // Set chart options
          const options = {
            curveType: "none",
            height: 1000,
            legend: { position: "bottom" },
            vAxis: {
              title: "Step",
              format: "#"
            },
            hAxis: {
              title: "Assembling Time",
              ticks: [
                { v: 0, f: "00:00:00" },
                { v: slowestBuildTime * 0.25, f: this.msToHMS(slowestBuildTime * 0.25) },
                { v: slowestBuildTime * 0.5, f: this.msToHMS(slowestBuildTime * 0.5) },
                { v: slowestBuildTime * 0.75, f: this.msToHMS(slowestBuildTime * 0.75) },
                { v: slowestBuildTime, f: this.msToHMS(slowestBuildTime) }
              ]
            }
          };

          // Instantiate and draw our chart, passing in some options.
          createChartWrapper(this.chartContainerTarget).then(chart => {
            chart.setChartType("LineChart");
            chart.setDataTable(data);
            chart.setOptions(options);
            chart.draw();
          });
        });
      });
  }

  // Callback that creates and populates a data table,
  // instantiates the pie chart, passes in the data and
  // draws it.
  msToHMS(ms, with_ms = false) {
    // 1- Convert to seconds:
    let seconds = Math.round(ms);
    // 2- Extract hours:
    let hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
    seconds = seconds % 3600; // seconds remaining after extracting hours
    // 3- Extract minutes:
    let minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
    // 4- Keep only seconds not extracted to minutes:
    seconds = seconds % 60;
    return (
      this.zeroPad(hours, 2) +
      ":" +
      this.zeroPad(minutes, 2) +
      ":" +
      this.zeroPad(seconds, 2) +
      (with_ms ? "." + (ms % 1000) : "")
    );
  }

  zeroPad(value, pad) {
    return ("00" + value).slice(-pad);
  }
}
