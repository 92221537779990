import { Controller } from "stimulus";

// var Trix = require("trix");

/**
 * Makes the text areas use richtext. Currently with quill, but the goal is to all for
 * other rich text WYSIWYG editor to be used and to make changes only in this controller
 *
 * @author Kiril Mitov
 */
export default class extends Controller {
  connect() {
    // Commenting this because I want to remove quite as it is not working for class="img-responsive img-content"
    //
    // this.setupQuilljs();
    // this.setupTrix();
  }

  /**
   * @private
   */
  setupTrix() {
    Trix.config.blockAttributes.default.tagName = "p";
    Trix.config.blockAttributes.default.breakOnReturn = true;

    Trix.Block.prototype.breaksOnReturn = function() {
      const attr = this.getLastAttribute();
      const config = Trix.getBlockConfig(attr ? attr : "default");
      return config ? config.breakOnReturn : false;
    };
    Trix.LineBreakInsertion.prototype.shouldInsertBlockBreak = function() {
      if (this.block.hasAttributes() && this.block.isListItem() && !this.block.isEmpty()) {
        return this.startLocation.offset > 0;
      } else {
        return !this.shouldBreakFormattedBlock() ? this.breaksOnReturn : false;
      }
    };
    Trix.config.blockAttributes.heading = {
      tagName: "h2",
      terminal: true,
      breakOnReturn: true,
      group: false
    };

    Trix.config.blockAttributes.subHeading = {
      tagName: "h3",
      terminal: true,
      breakOnReturn: true,
      group: false
    };

    const areas = document.querySelectorAll("textarea");
    areas.forEach(textarea => {
      // Hides the raw text are and add a new text area over it.
      // At the end we will copy the Quill content and set it to this
      // text area
      textarea.hidden = true;
      textarea.after(
        new DOMParser().parseFromString(
          `<div>
            <trix-toolbar id="${textarea.id}-toolbar"></trix-toolbar>
            <trix-editor input="${textarea.id}" toolbar="${textarea.id}-toolbar" class="form-control"></trix-editor>
            </trix-editor>
          </div>
          `,
          "text/html"
        ).body.firstChild
      );

      // Grab a reference to the toolbar(s) on the page.
      const toolbar = document.querySelector(`#${textarea.id}-toolbar`);
      // HTML for our buttons
      const h2ButtonHTML =
        '<button type="button" class="trix-button" data-trix-attribute="heading" title="Subheading">H2</button>';
      const h3ButtonHTML =
        '<button type="button" class="trix-button" data-trix-attribute="subHeading" title="Subheading">H3</button>';
      // Only apply event listeners once to the toolbars
      const once = {
        once: true
      };

      addEventListener(
        "trix-initialize",
        function(event) {
          const sibling1 = toolbar.querySelector(".trix-button--icon-increase-nesting-level");
          sibling1.insertAdjacentHTML("afterend", h2ButtonHTML);
          const sibling2 = toolbar.querySelector("[data-trix-attribute='heading']");
          sibling2.insertAdjacentHTML("afterend", h3ButtonHTML);
        },
        once
      );
    });
  }

  /**
   * @private
   */
  setupQuilljs() {
    const areas = document.querySelectorAll("textarea");
    areas.forEach(textarea => {
      // Hides the raw text are and add a new text area over it.
      // At the end we will copy the Quill content and set it to this
      // text area
      textarea.hidden = true;
      textarea.after(
        new DOMParser().parseFromString(
          `
          <div id="${textarea.id}-quill-container" class="form-control">
            <div id="${textarea.id}-quill-editor">
              ${textarea.value}
            </div>
          </div>
          `,
          "text/html"
        ).body.firstChild
      );
      this.createQuilljsEditorForArea(textarea);
    });
  }

  /**
   * @private
   */
  createQuilljsEditorForArea(textarea) {
    const options = {
      modules: {
        toolbar: [[{ header: [2, 3, 4, false] }], ["bold", "italic", "underline"], ["code-block", "image"], ["clean"]]
      },
      theme: "snow"
    };
    const quillEditor = new Quill(`#${textarea.id}-quill-editor`, options);
    const toolbar = quillEditor.getModule("toolbar");
    toolbar.addHandler("image", value => {
      debugger;
      this.openImageDialog(value, quillEditor);
    });
    const form = textarea.closest("form");
    form.addEventListener("submit", event => {
      textarea.value = quillEditor.root.innerHTML;
      event.preventDefault();
    });
  }

  /**
   * @private
   */
  openImageDialog(value, quillEditor) {
    const [range] = quillEditor.selection.getRange();
    const delta = new Delta()
      .retain(range.index)
      .delete(range.length)
      .concat("<p>MyThing</p>");
    quillEditor.updateContents(delta, Quill.sources.USER);
    quillEditor.setSelection(delta.length() - range.length, Quill.sources.SILENT);
    quillEditor.scrollIntoView();
  }
}
