import { Controller } from "stimulus";

/**
 * @author Kiril Mitov
 */
export default class extends Controller {
  static targets = [
    "form",
    "username",
    "name",
    "reset",
    "password",
    "passwordConfirmation",
    "isAutogeneratedUsername",
    "showPassword"
  ];

  connect() {}

  refreshForm() {
    if (this.isAutogeneratedUsernameTarget.value == "true") {
      this.resetTarget.click();
      // } else {
      //   this.formTarget.dispatchEvent(new Event('submit', {bubbles: true}));
    }
  }

  markUsernameAsNotAutogenerated() {
    this.isAutogeneratedUsernameTarget.value = "false";
  }

  generatePassword() {
    const newPassword = this.newPassword();
    this.passwordTarget.value = newPassword;
    this.passwordConfirmationTarget.value = newPassword;
  }

  toggleShowPassword() {
    if (this.showPasswordTarget.checked) {
      this.passwordTarget.type = "text";
      // The registrations controller is used on the login page and there is no password confirmation there
      if (this.hasPasswordConfirmationTarget) {
        this.passwordConfirmationTarget.type = "text";
      }
    } else {
      this.passwordTarget.type = "password";
      if (this.hasPasswordConfirmationTarget) {
        this.passwordConfirmationTarget.type = "password";
      }
    }
  }

  newPassword() {
    // Copying this from somewhere
    const DEFAULT_PASSWORD_LENGTH = 8;

    const LOWER_ALPHABET = "abcdefghijklmnopqrstuvwxyz".split("");
    const UPPER_ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
    const DIGITS = "0123456789".split("");
    const SPECIAL_SYMBOLS = "!@#$".split("");

    // Some letters that may be harder to write, so they are getting excluded from the usual letters
    const EXCLUDED_SYMBOLS = "0OIlji";

    // Add all symbols, but remove excluded symbols
    const DEFAULT_PASSWORD_SYMBOLS = [LOWER_ALPHABET, UPPER_ALPHABET, DIGITS, SPECIAL_SYMBOLS]
      .reduce((acc, val) => acc.concat(val), [])
      .filter(x => !EXCLUDED_SYMBOLS.includes(x));

    function generate_password(length = DEFAULT_PASSWORD_LENGTH, symbols = DEFAULT_PASSWORD_SYMBOLS) {
      let array = new Uint32Array(length);
      window.crypto.getRandomValues(array);

      let result = "";

      array.forEach(function(random_number) {
        let random_char_pos = random_number % symbols.length;
        result += symbols[random_char_pos];
      });

      return result;
    }

    return generate_password();
  }
}
