import { Controller } from "stimulus";

/**
 * @author Kiril Mitov
 */
export default class extends Controller {
  static targets = ["source"];

  connect() {
    if (document.queryCommandSupported("copy")) {
      this.element.classList.add("clipboard--supported");
    }
  }

  copy(event) {
    event.preventDefault();
    this.sourceTarget.select();
    document.execCommand("copy");
  }
}
