import { Controller } from "stimulus";
/**
 * <p>This controller looks for a context store button and attaches a handler
 * so that when the user presses the button a pop up will appear.</p>
 *
 * <p>It also takes a param from the url and if there is an item-paths in the url
 * it will accept them and open the popup right a way. </p>
 *
 * <p>Url params are</p>
 *
 * <pre><code>
 *  - item-paths - a comma separated paths of the fastspring items
 *  - state - if the value is 'checkout' we will open the popup
 *  - promo-code - the promo-code to be used
 *  - checkout-action - ['checkout', 'viewCart'] to trigger the different checkout actions
 * </code></pre>
 *
 * @author Kiril Mitov
 */
class FscContextStoreButtonController extends Controller {
  static targets = ["button"];

  /**
   */
  connect() {
    const dataItemPaths = this.buttonTarget.dataset["itemPaths"];
    const promoCode = this.buttonTarget.dataset["promoCode"];
    const checkoutAction = this.buttonTarget.dataset["checkoutAction"];
    const currentUserUuidJson = JSON.parse(document.getElementById("current_user_uuid_json").innerText);
    const scope = this;
    this.buttonTarget.addEventListener("click", () => {
      scope.checkout(dataItemPaths, currentUserUuidJson, checkoutAction, promoCode);
    });

    if (window.fscPopUpAlreadyOpenedFromUrl != true) {
      // If there are two buttons they will both try to parse the url
      // We want only one of them to parse the url so that
      // only one pop up is open
      window.fscPopUpAlreadyOpenedFromUrl = true;
      const uri = new URL(window.location.href);
      // it is checkout_v2 as there already is a 'checkout' for the /teach-at-school implementation
      if (uri.searchParams.get("state") == "checkout_v2") {
        const dataItemPaths = uri.searchParams.get("item-paths");
        const checkoutAction = uri.searchParams.get("checkout-action");
        if (dataItemPaths !== undefined) {
          this.checkout(
            dataItemPaths,
            currentUserUuidJson,
            checkoutAction,
            new URL(window.location.href).searchParams.get("promo-code")
          );
        }
      }
    }
  }

  /**
   * This method opens the fastspring checkout popup for specific item paths
   * @private
   * @param {string} dataItemPaths a comma separated string of item paths
   * @param {string} userUuidJson the user uuid tag. Its a json in the form
   *                 {"fllcastsUserUuid":"468b1b1c-30aa-4047-b0d0-ae0aa541a452"}
   * @param {string} checkoutAction 'viewCart' or 'checkout'. By default it is 'checkout'
   * @param {string=} promoCode optional promocode
   */
  checkout(dataItemPaths, userUuidJson, checkoutAction = "checkout", promoCode = null) {
    fastspring.builder.reset();

    dataItemPaths.split(",").forEach(plan => {
      fastspring.builder.add(plan);
    });
    if (promoCode) {
      fastspring.builder.promo(promoCode);
    }
    fastspring.builder.tag(userUuidJson);
    if (checkoutAction == "viewCart") {
      // When there are more than one products as in the team we want to support them
      // and not view the checkout but the viewCart(). The reason is that
      // checkout show the checkout with only one of the products and it is
      // difficult for the user to understand why is he buying only one product
      fastspring.builder.viewCart();
    } else {
      fastspring.builder.checkout();
    }
  }
}
export default FscContextStoreButtonController;
