import { Controller } from "stimulus";
const Uppy = require("@uppy/core");
const Dashboard = require("@uppy/dashboard");
// const GoogleDrive = require("@uppy/google-drive");
// const Dropbox = require("@uppy/dropbox");
// const Instagram = require("@uppy/instagram");
// const Facebook = require("@uppy/facebook");
// const OneDrive = require("@uppy/onedrive");
// const Webcam = require("@uppy/webcam");
// const ScreenCapture = require("@uppy/screen-capture");
const AwsS3 = require("@uppy/aws-s3");
const bg = require("@uppy/locales/lib/bg_BG");
const en = require("@uppy/locales/lib/en_US");

const locales = { "en": en, "bg": bg };

/**
 * Uploads file with Uppy and Direct upload to S3
 *
 * @author Kiril Mitov
 */
export default class extends Controller {
  static targets = ["parent", "uploadedFiles", "container", "uploadDataHidden", "uploadDataFile", "name"];
  connect() {
    // connect is called multiple times. dont know why yet, but it is
    // in the documentation
    // https://stimulusjs.org/reference/lifecycle-callbacks#reconnection
    if (!this.hasUploadDataFileTarget) {
      return;
    }

    const uppy = this.attach();
    uppy.on("upload-success", (file, response) => {
      if (this.hasUploadedFilesTarget) {
        this.uploadedFilesTarget.innerHTML = "";
      }
    });
  }

  attach() {
    const fileInput = this.uploadDataFileTarget;
    const hiddenInput = this.uploadDataHiddenTarget;

    // Do not remove but rather leave here for the specs.
    fileInput.disabled = true;
    fileInput.style.visibility = "hidden";
    // remove our file input in favour of Uppy's
    // This removes the "Choose file" button
    // formGroup.removeChild(fileInput)

    const uppy = this.setupUppy();
    const uppyController = this;
    uppy.on("upload-success", (file, response) => {
      // construct uploaded file data in the format that Shrine expects
      const uploadedFileData = {
        id: file.meta["key"].match(/^cache\/(.+)/)[1], // object key without prefix
        storage: "cache",
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type
        }
      };

      // set hidden field value to the uploaded file data so that it's submitted
      // with the form as the attachment
      hiddenInput.value = JSON.stringify(uploadedFileData);

      uppyController.setResourceNameFromFilename(uploadedFileData["metadata"]["filename"]);
      uppyController.autoSubmitOnUpload(hiddenInput.form);
    });

    // uppy.on('complete', result => {
    //   console.log('successful files:', result.successful)
    //   console.log('failed files:', result.failed)
    // })
    return uppy;
  }

  /**
   * Setting the value of the field for the reource name if it is present.
   * @private
   * @param {Object} data The upload file data that is generated
   */
  setResourceNameFromFilename(fileName) {
    if (!this.hasNameTarget) {
      return;
    }
    this.nameTarget.value = fileName;

    let shouldStripExtensionFromFileName = false;

    // This will be fixed with Values from Stimulus I have an issue and when it gets resolved it will be better.
    // For now the idea is to be as simple to refactor later as possible
    if (this.uploadDataFileTarget.dataset.shouldStripExtensionFromFileName == "true") {
      shouldStripExtensionFromFileName = true;
    }

    const stripFileNameExtension = file => {
      if (file.indexOf(".") > 0) {
        return file
          .split(".")
          .slice(0, -1)
          .join(".");
      } else {
        return file;
      }
    };

    if (shouldStripExtensionFromFileName) {
      this.nameTarget.value = stripFileNameExtension(fileName);
    }
  }

  /**
   * @private
   * @param {Element} form The HTML form we want to submit.
   */
  autoSubmitOnUpload(form) {
    const fileInput = this.uploadDataFileTarget;
    const formGroup = fileInput.parentNode;
    if (fileInput.dataset.autoSubmitOnUpload == "true") {
      const span = document.createElement("span");
      span.innerHTML = "Updating. Please wait...";
      formGroup.appendChild(span);

      form.dispatchEvent(new Event("submit", { bubbles: true }));
    }
  }
  /**
   * Constructing the Uppy Object
   * @private
   * @return {Uppy}
   */
  setupUppy() {
    const fileInput = this.uploadDataFileTarget;
    const autoProceed = fileInput.dataset.autoProceed == "true";
    const maxFileSize = parseInt(fileInput.dataset.uppyMaxFileSize, 10);

    // Allow all file types if nothing is passed
    const allowedFileTypes = fileInput.dataset.uppyAllowedFilesTypes
      ? fileInput.dataset.uppyAllowedFileTypes.split(",")
      : null;

    return Uppy({
      // debug: true,
      autoProceed: autoProceed,
      allowMultipleUploads: false,
      // 'bg' or 'en' or 'ru' or what ever...
      // if null we will get null from the object defined on the top of the doc
      // Uppy is smart enough to know that we pass null locale and default to en.
      locale: locales[fileInput.dataset.locale],
      restrictions: {
        maxFileSize: maxFileSize,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: allowedFileTypes
      }
    })
      .use(Dashboard, {
        inline: true,
        target: this.containerTarget,
        replaceTargetContent: true,
        showProgressDetails: true,
        note: `Files up to ${maxFileSize / 1024 / 1024} MB`,
        height: 350,
        // metaFields: [
        //   // { id: 'name', name: 'Name', placeholder: 'file name' },
        //   // { id: 'caption', name: 'Caption', placeholder: 'describe what the image is about' }
        // ],
        browserBackButtonClose: true
      })
      .use(AwsS3, {
        companionUrl: "/" // will call the presign endpoint on `/s3/params`
      });
    // .use(GoogleDrive, { target: Dashboard, companionUrl: 'https://companion.uppy.io' })
    // .use(Dropbox, { target: Dashboard, companionUrl: 'https://companion.uppy.io' })
    // .use(Instagram, { target: Dashboard, companionUrl: 'https://companion.uppy.io' })
    // .use(Facebook, { target: Dashboard, companionUrl: 'https://companion.uppy.io' })
    // .use(OneDrive, { target: Dashboard, companionUrl: 'https://companion.uppy.io' })
    // .use(Webcam, { target: Dashboard })
    // .use(ScreenCapture, { target: Dashboard });
    // .use(Tus, { endpoint: 'https://master.tus.io/files/' })
  }
}
