import { Controller } from "stimulus";

/**
 * @author Kiril Mitov
 */
export default class extends Controller {
  static targets = ["instructor", "groupRead", "groupProgressManage"];

  connect() {}

  clickInstructor() {
    if (this.instructorTarget.checked) {
      ["group_role_permissions_name_groups_read", "group_role_permissions_name_group_progress_manage"].forEach(id => {
        if (!document.querySelector(`#${id}`).checked) {
          document.querySelector(`label[for='${id}']`).click();
        }
      });
    }
  }
}
