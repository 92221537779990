import { Controller } from "stimulus";

/**
 * Controller that handles loading the release_pack
 *
 * @author Dimitar Lukanov
 */
export default class extends Controller {
  static targets = ["isJsViewer"];

  initialize() {
    if (this.shouldNotLoadReleasePack()) return;
    if (!window.atob) this.displayNotSupported();

    const data = JSON.parse(this.isJsViewerTarget.dataset["is"]);

    let releasePackUrl = data["server-load"];
    const configuration = data["configuration"];
    const version = data["configurationVersion"];

    releasePackUrl += `?is_configuration=${configuration}`;

    if (version) {
      releasePackUrl += `&is_version=${version}`;
    }

    this.loadScript(releasePackUrl, function(data) {
      try {
        eval(window.atob(data));
      } catch (err) {
        this.displayNotSupported();
      }
    });
  }

  shouldNotLoadReleasePack() {
    const isMode = new URLSearchParams(window.location.search).get("is_mode");
    return !this.hasIsJsViewerTarget || isMode == "sdk";
  }

  /**
   * Empties out the isJsViewer targets and adds a well explaining why the instructions cannot be loaded
   */
  displayNotSupported() {
    this.isJsViewerTarget.innerHTML = "";
    const notSupportedDiv = document.createElement("div");
    notSupportedDiv.addClass("well");
    notSupportedDiv.text(
      "Unfortunately your browser does not support showing the 3D building instructions. We are notified for this and we are working on a solution. Thank you for your patience."
    );

    this.isJsViewerTarget.appendChild(notSupportedDiv);
  }

  loadScript(url, callback) {
    jQuery.ajaxSetup({
      headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
      }
    });

    jQuery.ajax({
      type: "POST",
      url: url,
      dataType: "text",
      success: callback,
      async: true
    });
  }
}
