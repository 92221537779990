// Sending Google Tag Manager event
// based on https://support.google.com/tagmanager/answer/6164391?hl=en
// we need to initialize the dataLayer before the tag manager container
//
window.dataLayer = window.dataLayer || [];
document.addEventListener("turbolinks:load", event => {
  if (!event.data.timing.visitStart) {
    // Initial page load
    // With the initial we send the data through the loading of the google tag manager on the page
  } else {
    // Turbolinks page load
    window.dataLayer.push({
      "event": "Turbo-Load",
      "pagePath": window.location.pathname + window.location.search + window.location.hash,
      "pageTitle": document.title,
      "isSubscribedSession": document.querySelector("body").dataset["isSubscribedSession"]
    });
  }
});
