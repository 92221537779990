require("fc/shared/data_layer_push")

document.addEventListener("turbolinks:load", function() {
  if (typeof FllcastsSubscriptionContextStore !== "undefined") FllcastsSubscriptionContextStore.init();
})

document.addEventListener("turbolinks:load", function() {
  Turbolinks.setProgressBarDelay(10);
})

// Initialization// Initialization of FLLCasts theme
document.addEventListener("turbolinks:load", function() {

  // initialization of carousel
  $.HSCore.components.HSCarousel.init(".js-carousel");

  // Header
  $.HSCore.components.HSHeader.init($("#js-header"));
  $.HSCore.helpers.HSHamburgers.init(".hamburger");

  // initialization of HSMegaMenu plugin
  $(".js-mega-menu").HSMegaMenu({
    event: "hover",
    pageContainer: $(".container"),
    breakpoint: 991
  });

  $.HSCore.helpers.HSFocusState.init();

  // initialization of go to
  $.HSCore.components.HSGoTo.init(".js-go-to");

  $.HSCore.helpers.HSHeightCalc.init();

  // initialization of HSScrollNav
  $.HSCore.components.HSScrollNav.init($("#js-scroll-nav"), {
    duration: 700,
    over: $(".u-secondary-navigation")
  });

  // initialization of masonry.js
  $(".masonry-grid")
    .imagesLoaded()
    .then(function() {
      $(".masonry-grid").masonry({
        // options
        columnWidth: ".masonry-grid-sizer",
        itemSelector: ".masonry-grid-item",
        percentPosition: true
      });
    });

  // initialization of sticky blocks
  $.HSCore.components.HSStickyBlock.init(".js-sticky-block");

 // initialization of datepicker
  $.HSCore.components.HSDatepicker.init('.u-datepicker-v1', {
    dayNamesMin: [
      'SU',
      'MO',
      'TU',
      'WE',
      'TH',
      'FR',
      'SA'
    ]
  });
  // initialization of counters
  // $.HSCore.components.HSCounter.init('[class*="js-counter"]');
  // // initialization of scroll animation
  // $.HSCore.components.HSOnScrollAnimation.init("[data-animation]");
});
