import 'core-js/stable'
import 'regenerator-runtime/runtime'

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()

// UnifyTheme2 requirements
require("jquery");
require("jquery-ui");
require('jquery-ui/ui/widget');
require('jquery-ui/ui/version');
require('jquery-ui/ui/keycode');
require('jquery-ui/ui/position');
require('jquery-ui/ui/unique-id');
require('jquery-ui/ui/safe-active-element');
require('jquery-ui/ui/widgets/menu');
require('jquery-ui/ui/widgets/mouse');
require('jquery-ui/ui/widgets/datepicker');
require('jquery-validation');
require('chosen-js');

// unify_theme2.6.3 needs bootstrap 4.4.1
require('bootstrap')
// unify_theme2.6.3 needs bootstrap-select 1.13.2 I am adding 1.13.17 from yarn
require('bootstrap-select')

// unify_theme2.6.3 needs popper.js 1.14.6
require('popper.js')

// unify_theme2.6.3 requires appear.js 1.0.3. We would install @ahkui/appear.js 1.3.0
require('@ahkui/appear.js')

// unify_theme2.6.3 needs flatpickr 4.1.2
require('flatpickr')

// unify_theme2.6.3 needs charlist 0.11.4
import Chartist from 'chartist';
require('chartist-plugin-tooltip')

// unify_theme2.6.3 require 3.1.24 I am adding 3.1.25 since this is available in npm. There is also
// a new version 3.5.7 - probably we should try it.
require("@fancyapps/fancybox")

require("unify_theme2/assets/vendor/hs-megamenu/src/hs.megamenu");
// removing paralaxer. It is failing with jquery3
// Used in app/views/devise/shared/_layout.html.erb:<section class="dzsparallaxer..
// require("unify_theme2/assets/vendor/dzsparallaxer/dzsparallaxer");
// require("unify_theme2/assets/vendor/dzsparallaxer/dzsscroller/scroller");
// require("unify_theme2/assets/vendor/dzsparallaxer/advancedscroller/plugin");
require("unify_theme2/assets/vendor/masonry/dist/masonry.pkgd.min");
require("imagesloaded");
require("unify_theme2/assets/vendor/image-select/src/ImageSelect.jquery")
require("slick-carousel")

// END UnifyTheme2 requirements

require("unify_theme2/assets/js/hs.core")
require("unify_theme2/assets/js/components/hs.carousel")
require("unify_theme2/assets/js/components/hs.counter")
require("unify_theme2/assets/js/components/hs.file-attachement")
require("unify_theme2/assets/js/components/hs.go-to")
require("unify_theme2/assets/js/components/hs.datepicker")
require("unify_theme2/assets/js/components/hs.header")
require("unify_theme2/assets/js/components/hs.onscroll-animation")
require("unify_theme2/assets/js/components/hs.scroll-nav")
require("unify_theme2/assets/js/components/hs.sticky-block")
require("unify_theme2/assets/js/components/hs.tabs")
require("unify_theme2/assets/js/components/hs.validation")
require("unify_theme2/assets/js/helpers/hs.focus-state")
require("unify_theme2/assets/js/helpers/hs.hamburgers")
require("unify_theme2/assets/js/helpers/hs.height-calc")
require("unify_theme2/assets/js/custom");

// UnifyTheme Init
require("fc/main_unify/init")
// END UnifyTheme Init

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

// Pointed at my gem
const fcGroupsEnrollContext = require.context('fc-groups_enroll/app/javascript/controllers', true, /\.js$/)
application.load(definitionsFromContext(fcGroupsEnrollContext))

import "is_dependencies";